.popup {
    height: 24rem;
    width: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: none;
    border-radius: 0.2rem;
    padding: 20px;
  }
  
  .popup__title {
    text-align: left;
    height: 3rem;
    width: 20rem;
    font-size: 3.2vh;
    font-weight: 700;
    margin-right: 18rem;
    margin-bottom: 5rem;
  }
  
  .popup__input {
      height: 2rem;
    width: 23rem;
    font-size: 3vh;
    font-weight: 600;
    display: flex;
    gap: 2vw;
    align-items: center;
    margin: 1vh 1vh 1vh 1vh;
  }
  
  .popup__input > input {
    width: 17vw;
    height: 3vh;
    border: 2px solid grey;
    border-radius: 6vh;
    padding: 0.5rem;
    font-size: 2vh;
    font-weight: 600;
  }
  
  .popup__color__input {
  
    font-size: 3vh;
    font-weight: 600;
    display: flex;
    justify-content: center;
    gap: 3vw;
    align-items: center;
    margin: 2vh 1vh 1vh 1vh;
  }
  
  .popup__color__input__color {
    display: flex;
    gap: 1vw;
  }
  
  .popup__color__input__color__1,
  .popup__color__input__color__2,
  .popup__color__input__color__3,
  .popup__color__input__color__4,
  .popup__color__input__color__5,
  .popup__color__input__color__6 {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: black;
  }
  
  .popup__color__input__color__1{
      background-color: #B38BFA;
  }
  
  .popup__color__input__color__2{
      background-color: #FF79F2;
  }
  
  .popup__color__input__color__3{
      background-color: #43E6FC;
  }
  
  .popup__color__input__color__4{
      background-color: #F19576;
  }
  
  .popup__color__input__color__5{
      background-color: #0047FF;
  }
  
  .popup__color__input__color__6{
      background-color: #6691FF;
  }
  
  .popup__close {
    height: 2rem;
    width: 23rem;
    display: flex;
    justify-content: flex-end;
    margin: 1vh 1vh 1vh 1vh;
  }
  
  .popup__close > button {
    margin-top: 1rem;
    height: 5vh;
    width: 8vw;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 4vh;
    background-color: black;
  }
  
  .highlight{
      border: 2px solid black;
      border-radius: 6vh;
  }