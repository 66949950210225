.mobile__notes__content__body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 10vh;
    height: auto;
    width: 95vw;
    margin: 1vh 0 1vh 0;
}

.mobile__notes__content__date__time__details{
    width: 40vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 10vh;
    background-color: #F7ECDC;
}

.mobile__notes__content__date, .mobile__notes__content__time{
    font-size: 2vh;
    font-weight: 600;
    margin-left: 2vh;
}

.mobile__notes__content__details{
    min-height: 10vh;
    height: auto;
    width: 55vw;
    text-align: left;
    margin: 0 0 0 1vw;
}