.mobile__notes{
    height: 10vh;
    width: 70vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0 2vh 3vh;
}

.mobile__notes__icon{
    height: 9vh;
    width: 9vh;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
}

.mobile__notes__title{
    width: 50vw;
    text-align: left;
    font-size: 3vh;
    font-weight: 600;
}