.desktop__sidebar {
    width: 24vw;
    height: 100vh;
  }
  
  .desktop__sidebar__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem;
    text-align: left;
  }
  
  .desktop__sidebar__create__notes__btn {
    height: 11vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .desktop__popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .desktop__sidebar__create__notes__btn > button {
    width: 80%;
    height: 6vh;
    border: none;
    border-radius: 2rem;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
  }
  
  #add {
    font-size: 30px;
  }
  
  .desktop__sidebar__notes__title {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 79vh;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }