.mobile__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .mobile__sidebar__title {
    text-align: left;
    height: 8vh;
    width: 80vw;
    font-size: 2rem;
    font-weight: 700;
    position: fixed;
    top: 3vh;
  }
  
  .mobile__sidebar__create__notes__btn {
    height: 8vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 12vh;
    background-color: white;
  }
  
  .mobile__sidebar__create__notes__btn > button {
    height: 8vh;
    width: 80vw;
    font-size: 3vh;
    border: none;
    border-radius: 5vh;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }
  
  .mobile__sidebar__notes__title {
    min-height: 74vh;
    margin-top: 22vh;
    overflow-y: auto;
  
    height: 50vh;
    width: 100vw;
  }
  
  .mobile__popup__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }