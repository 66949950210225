.desktop__home{
    width: 75vw;
    height: 100vh;
    background-color: #F7ECDC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.desktop__home > img {
    margin-top: 25vh;
    width: 35vw;
    height: 35vh;
}

.desktop__home > h1,p {
    margin: 0;
    font-weight: 500;
}

.desktop__home__bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 10vh;
    font-size: 1rem;
    font-weight: 600;
}

.desktop__home__bottom > img {
    width: 1.2vw;
    height: 1.2vw;
}